import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { clientConnection } from 'services'
import { getToken } from 'utils'

const USER_INFO = process.env.REACT_APP_USER_INFO_KEY

const useUserConnection = () => {
  const params = useParams()
  const location = useLocation() 
  const [ user, setUser ] = useState('Default User')

  useEffect(() => {
    const URL = decodeURI(location.pathname)

    if (URL.includes('widget')) {
      clientConnection(params.user)
      .then(user => setUser(user))
    } else {
      setUser(JSON.parse(getToken(USER_INFO)))
    }
  }, [location])
  return user
}

export default useUserConnection