import React, { useRef, useEffect } from "react";
import Message from "../Message";
import { Spinner } from "components";
import styles from "./styles.module.scss";
import { useMessages } from "hooks";

const ChatBody = ({bottom, top, setTop, hidden}) => {
  const ref = useRef(null)
  const { msgs, batchMsgs, lastMsgRef } = useMessages({
    bottom,
    load: top,
    setLoad: setTop
  })

  useEffect(() => {
    hidden(msgs.length === 0)
    return () => hidden(true)
  }, [msgs])

  const renderMessages = (array) => {
    return array.map((e, i, messages) => {
      return (
        <Message key={e.id} body={e} prev={i != 0 && messages[i - 1].user}>
          {e.type === "text" && e.msg}
        </Message>
      )
    })
  }

  return (
    <>
      <li ref={ref} className={`${styles.observer} ${top && styles.show}`}>
        <Spinner/>
      </li>
      {
        msgs.length > 0 || batchMsgs.length > 0 ?
        <>
          { renderMessages(batchMsgs) }
          <li ref={lastMsgRef}><hr/></li>
          { renderMessages(msgs) }
        </>
        : (msgs.length == 0 && batchMsgs.length == 0 ? 
            <li className={`${styles.wrapper} ${styles.center}`}>
              <p className={styles.text}>No hay mensajes</p>
            </li>
            : 
            <li className={`${styles.wrapper} ${styles.center}`}>
              <Spinner text="Cargando mensajes..." />
            </li>
          )
      }
      <li ref={bottom}/>
    </>
  )
}

export default ChatBody