import React from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import logoLight from "assets/images/transparent-icon.png"
import lightTextLogo from "assets/images/logo-text.png"
import styles from './styles.module.scss'

const Logo = () => {
  const { user } = useParams()
  return (
    <div className={styles["navbar-header"]}>
      <Link className={`navbar-brand ${styles.logo}`} to={`/dashboard/${user}/system`}>
        <b>
          <img src={logoLight} alt="homepage" className={`${styles["logo"]}`} />
        </b>
        <span className={styles['logo-hidden']}>
          <img src={lightTextLogo} className="light-logo" alt="homepage" />
        </span>
      </Link>
    </div>
  )
}

export default Logo