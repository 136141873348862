import Axios from './config'

const { REACT_APP_POST_MESSAGE } = process.env

const postMessage = async (msg) => {
    msg.type == 'file'
    ? await Axios.post(REACT_APP_POST_MESSAGE, msg, { headers: { 'Content-Type': 'multipart/form-data' } })
    : await Axios.post(REACT_APP_POST_MESSAGE, msg)
}

export default postMessage