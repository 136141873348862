const getTime = () => {
  const time = new Date()
  const hours = time.getHours()
  const formatHours = hours%12 === 0 ? 12: hours % 12
  const minutes = String(time.getMinutes())
  const formatMinutes = minutes.length === 1 ? `0${minutes}` : minutes

  return `${formatHours}:${formatMinutes}${hours >= 12 ? "pm": "am"}`
}

export default getTime