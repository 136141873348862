import Axios from "./config";

const { REACT_APP_GET_MESSAGES } = process.env;

const getChatMessages = async (chatUuuid, page = 1) => {
  try {
    const { data } = await Axios.get(`${REACT_APP_GET_MESSAGES}${chatUuuid}?page=${page}`);
    return data;
  } catch (error) {
    console.error(error);
    return error
  }
};

export default getChatMessages;
