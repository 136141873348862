import React, { useContext } from 'react'
import { OverlayContext, FileContext, VisibleContext } from 'context'
import { getFileExtension } from 'utils'
import styles from './styles.module.scss'

const FileCardContent = () => {
  // esto solo funciona para archivos locales
  // TODO: permitir archivos desde el servidor
  const name = useContext(FileContext).file.name
  const extension = getFileExtension(name)
  const { setOverlay } = useContext(OverlayContext)
  const { setVisible } = useContext(VisibleContext)

  return (
    <>
      <div className={styles['icon-container']}
        onClick={() => setOverlay(true)}
        onContextMenu={() => setVisible(true)}
      >
        <i className={`mdi mdi-file ${styles.icon}`}/>
        <span>
          { extension.slice(0, 4) }
          { extension.length > 4 ? '...' : '' }
        </span>
      </div>
      <p>{name}</p>
    </>
  )
}

export default FileCardContent