import React from 'react'
import Logo from './Logo'
import NavBody from './NavBody'
import styles from './styles.module.scss'

const NavBar = () => {
  return (
    <header className={`topbar ${styles['topbar-relative']}`}>
      <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <Logo/>
        <NavBody/>
      </nav>
    </header>
  )
}

export default NavBar