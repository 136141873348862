import { useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { ToggleContext } from "context"
import { getToken } from "utils"

const { REACT_APP_CHAT_UUID } = process.env

const useChannel = ({usr}) => {
  const { clientChat } = useContext(ToggleContext)
  const [ channel, setChannel ] = useState(null)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes("widget"))
      setChannel(getToken(REACT_APP_CHAT_UUID))
    else {
      if (usr === undefined)
        setChannel(null)
      else
        setChannel(clientChat)
    }
  }, [usr, location])

  return { channel }
}

export default useChannel