import React, { useState, useContext, useRef, useEffect } from 'react'
import { ChatContext, ToggleContext, ServerContext,
         FileContext, ErrorContext, VisibleContext } from 'context'
import { StyledButton } from 'components'
import { handleChildrenBlur, validateFileType } from 'utils'
import ChatInput from '../ChatInput'
import ChatHeader from '../ChatHeader'
import ChatBox from './ChatBox'
import styles from './styles.module.scss'

const ChatMainArea = ({ showChannel }) => {
  const { setFile } = useContext(FileContext)
  const { toggle, setToggle, client, setClient, setClientChat } = useContext(ToggleContext)
  const server = useContext(ServerContext)?.server
  const [ visible, setVisible ] = useState(false)
  const [ drag, setDrag ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ pos, setPos ] = useState({})
  const [ chatStatus, setChatStatus ] = useState(localStorage.getItem(process.env.REACT_APP_CHAT_STATUS))
  const menuArea = useRef(null)
  const menu = useRef(null)
  
  useEffect(() => {
    setClient(null)
    setClientChat(null)
  }, [server])

  const handleDrop = e => {
    e.preventDefault()
    setDrag(false)

    let file = e.dataTransfer.files[0]
    
    if (validateFileType(file.type))
      setFile(file)
    else {
      setError(true)
      setTimeout(() => setError(false), 3000)
    }
  }

  const handleDrag = e => {
    setDrag(true)
    e.stopPropagation()
    e.preventDefault()
  }

  const handleContextMenu = e => {
    e.preventDefault()
    if (visible) setVisible(false)

    let menuWidth = menu.current.offsetWidth
    let inputPadding = 15

    const { current } = menuArea

    let rect = current.getBoundingClientRect()
    let width = current.offsetWidth
    let height = current.offsetHeight
    
    let x = e.pageX - rect.left
    let y = e.pageY - rect.top
    
    if (x > width - menuWidth)
      x = width - menuWidth
    if (y > height - inputPadding)
      y = height - inputPadding

    setPos({x, y})
  }

  return (
    <div className="chat-right-aside d-flex flex-column w-100"
      onDragOver={handleDrag}
      onDragLeave={(e) => handleChildrenBlur(e, setDrag)}
    >
      { showChannel && 
        <StyledButton styling={`open-panel ${styles['toggle-panel']} ${toggle && styles['pannel-toggled']}`} 
          onClick={() => setToggle(prev => !prev)}>
          <i className={toggle ? 'ti-angle-left' : 'ti-angle-right'}></i>
        </StyledButton>
      }
      <ChatContext.Provider value={{chatStatus, setChatStatus}}>
        <ChatHeader title={server ?? ''} name={client?.name} email={client?.email} />
        <ErrorContext.Provider value={{error, setError}}>
          <VisibleContext.Provider value={{visible, setVisible}}>
            <div className={styles['chat-wrapper']}
              ref={menuArea}
              onContextMenu={handleContextMenu}
              onClick={() => setVisible(false)}
            >
              <ChatBox pos={pos} 
                ref={menu} 
                drag={drag} 
                handleDrop={handleDrop}
              />
            </div>
            <ChatInput/>
          </VisibleContext.Provider>
        </ErrorContext.Provider>
      </ChatContext.Provider>
    </div>
  )
}

export default ChatMainArea