import React from 'react'
import styles from './styles.module.scss'

const TabItem = ({active, setActive, children}) => {
  return (
    <li className={`col text-center ${styles.nav} ${active === children.href && styles.active}`}>
      <button className={`${styles.tab}`}
        onClick={() => setActive(children.href)}
      >
        {children.name}
      </button>
      <div className={styles.accent}/>
    </li>
  )
}

export default TabItem