import Axios from "./config";

const { REACT_APP_GET_SYSTEMS } = process.env;

const getSystems = async () => {
  const { data } = await Axios.get(REACT_APP_GET_SYSTEMS);
  return data;
};

export default getSystems;
