import React, { useContext, useEffect, useState } from 'react'
import { ToggleButton, StyledButton } from 'components'
import { SocketContext, ToggleContext, UserContext } from 'context' 
import { closeChat, getContribuitors, leaveChat } from 'services'
import UserCard from '../ChannelBar/UserCard'
import styles from './styles.module.scss'

const ContribuitorBar = () => {
  const [ toggle, setToggle ] = useState(false)
  const [ contribuitors, setContribuitors ] = useState([])
  const { clientChat, client, setClient, setClientChat } = useContext(ToggleContext)
  const { user } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  
  useEffect(() => {
    if (clientChat) {
      getContribuitors(clientChat).then(data => {
        setContribuitors(data);
      });
    }
  }, [clientChat]);

  useEffect(() => {
    if (clientChat && user) {
      socket?.private('notifications.user.' + user.id)
        .notification(data => {
          if (data.type == 'chat-contribuitor-joined') {
            if (data.chat == clientChat) {
              const contribuitor = contribuitors.find(c => c.id == data.user.id);
              if (!contribuitor) {
                setContribuitors(prev => [...prev, data.user])
              }
            } 
          }
          else if (data.type == 'chat-contribuitor-left') {
            if (data.chat == clientChat) {
              setContribuitors(prev => prev.filter(e => e.id != data.user.id))
            }
          }
        });
    }
  }, []);

  const handleLeaveChat = () => {
    leaveChat(clientChat).then(() => {
      setClientChat(null);
      setContribuitors([]);
      setClient(null);
      setToggle(false);
    });
  }

  const createTicket = () => {
    window.open(process.env.REACT_APP_BACKEND_URL + `/tickets/create?client_id=${client.id}&system_id=${client.system_id}`);
  }

  return (
    <div className={toggle ? styles['side-open']: styles['side-close']}>
      <div className={styles['side-header']}>
        <ToggleButton toggle={toggle} onClick={() => setToggle(prev=>!prev)}/>
      </div>
      <div className={toggle? styles['body-show']: styles['body-hide']}>
        <div className={styles["list-container"]}>
          <ul className={styles.list}>
            <h5 className="card-title m-l-5 m-r-10 p-20">Participantes</h5>
            {contribuitors.length 
              ? contribuitors.map((e, i) => <UserCard key={i}>{e.name}</UserCard>)
              : <span className="m-l-5 m-r-10 p-10">No hay nadie</span>
            }
            <hr />
            <h5 className="card-title m-l-5 p-l-20 p-t-10">Opciones</h5>
            <li>
              <StyledButton styling={styles["item-btn"]} onClick={() => createTicket()}>
                <i className="fas fa-ticket-alt"></i>
                <span>Crear ticket</span>
              </StyledButton>
            </li>
            <li>
              <StyledButton styling={styles["item-btn"]} onClick={() => handleLeaveChat()}>
                <i className="fas fa-sign-out-alt"></i>
                <span>Dejar chat</span>
              </StyledButton>
            </li>
            <li>
              <StyledButton styling={styles["item-btn"]} onClick={() => closeChat(clientChat)}>
                <i className="fas fa-times"></i>
                <span>Cerrar chat</span>
              </StyledButton>
            </li>
            <li className="p-20"></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ContribuitorBar