import React, { useState } from 'react'
import styles from './styles.module.scss'

const StyledButton = ({ children, styling, ...props }) => {
  const [ pos ,setPos ] = useState({x: 0, y: 0})

  const getPosition = evt => {
    // Estas coordenadas nos permitirán añadir una animación a los botones
    let rect = evt.target.getBoundingClientRect()
    let x = evt.pageX - rect.left
    let y = evt.pageY - rect.top
    setPos({x, y})
  }

  return (
    <button className={`${styles.btn} ${styling}`} 
      style={{"--x-pos": `${pos.x}px`, "--y-pos": `${pos.y}px`}}
      onMouseMove={getPosition}
      {...props}
    >
      {children}
    </button>
  )
}

export default StyledButton