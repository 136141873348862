import React, { useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { StyledButton } from 'components'
import { HoverContext, ServerContext } from 'context'
import styles from './styles.module.scss'

const NavButton = ({ children, name, active, href }) => {
  const user = useParams().user ?? 'Default User'
  const navigate = useNavigate()
  const location = useLocation()
  const { setHover } = useContext(HoverContext)
  const { setServer } = useContext(ServerContext)
  
  const handleClick = () => {
    let url = `/dashboard/${user}/${href}`
    if (decodeURI(location.pathname) !== url) {
      setServer(name)
      navigate(url)
    }
  }

  return (
    <li className="nav-item dropdown">
      <StyledButton 
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)} 
        styling={`waves-effect ${styles["anchor-button"]}
        ${active && styles.active}`}
      >
        { children }
      </StyledButton>
    </li>
  )
}

export default NavButton