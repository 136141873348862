import React, { useEffect, useContext } from 'react'
import { ServerContext } from 'context'
import { TabNavigator } from 'components'
import { CardContainer } from 'components'
import Content from './Content'

const Analytics = () => {
  const { server, setServer } = useContext(ServerContext)
  const TABS = [{
    name: 'Chats',
    href: 'systems'
  },{
    name: 'Tickets',
    href: 'tickets'
  }]

  useEffect(() => {
    if (server !== 'Estadisticas')
      setServer('Estadisticas')
  }, [])
  
  return (
    <CardContainer>
      <div className="d-flex flex-column p-20 w-100">
        <header className="card-header text-center">
          <h3 className="card-title">Estadísticas</h3>
          <TabNavigator tabs={TABS}/>
        </header>
        <div className="card-body" style={{overflowY: 'auto'}}>
          <Content/>
        </div>
      </div>
    </CardContainer>
  )
}

export default Analytics