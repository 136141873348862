import React from 'react'
import { UserImage } from 'components'
import { useParams } from 'react-router-dom'
import NavButton from '../NavButton'
import UserDropdown from './UserDropdown'
import styles from './styles.module.scss'

const User = () => {
  const user = useParams().user ?? 'Default User'

  return (
    <NavButton 
      dropdown={<UserDropdown/>}
      pos={0} 
    >
      <UserImage 
        styling={styles['user-image']} 
        user={user}
      />
    </NavButton>
  )
}

export default User