import React from 'react'

const UserInfo = ({ name, last_name, email, created_at }) => {
  return (
    <div className="row">
      <div className="col-md-3 col-xs-6 b-r"> 
        <strong>Nombre(s)</strong>
        <br/>
        <p className="text-muted">{name}</p>
      </div>
      <div className="col-md-3 col-xs-6 b-r">
        <strong>Apellido(s)</strong>
        <br/>
        <p className="text-muted">{last_name}</p>
      </div>
      <div className="col-md-3 col-xs-6 b-r">
        <strong>Correo electrónico</strong>
        <br/>
        <p className="text-muted">{email}</p>
      </div>
      <div className="col-md-3 col-xs-6">
        <strong>Miembro desde</strong>
        <br/>
        <p className="text-muted">{new Date(created_at).toLocaleDateString()}</p>
      </div>
    </div>
  )
}

export default UserInfo