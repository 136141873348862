import { useState, useEffect } from 'react'
import io from 'socket.io-client'
import Echo from 'laravel-echo'

const { REACT_APP_LARAVEL_ECHO_URL } = process.env

let ECHO = null

const getConnection = (token) => {
  if (ECHO === null) {
    return new Echo({
      client: io,
      broadcaster: 'socket.io',
      host: REACT_APP_LARAVEL_ECHO_URL,
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: {
        headers: {
          Authorization: token
        },
      },
    })
  }
  return ECHO
}

const useSocket = (token) => {
  const [ socket, setSocket ] = useState(null)

  useEffect(() => {
    if (socket === null && token) 
      setSocket(getConnection(token))
  }, [socket, token])

  return socket
}

export default useSocket