import React, { useContext } from 'react'
import { StyledButton } from 'components'
import { downloadFile, getBase64 } from 'utils'
import { FileContext, OverlayContext } from 'context'
import styles from './styles.module.scss'

const Menu = () => {
  const { setOverlay } = useContext(OverlayContext)
  const { file, fileMessage } = useContext(FileContext)

  const handleDownload = () => {
    if (file) {
      getBase64(file).then(res => downloadFile(res))
    }
    else if (fileMessage) {
      downloadFile(fileMessage.url)
    }
  }

  return (
    <>
      <StyledButton styling={styles.item} onClick={() => setOverlay(true)}>
        <i className={`mdi mdi-eye ${styles.icon}`}/>
        Previsualizar
      </StyledButton>
      <StyledButton styling={styles.item} onClick={handleDownload}>
        <i className={`mdi mdi-download ${styles.icon}`}/>
        Descargar
      </StyledButton>
    </>
  )
}

export default Menu