import Axios from './config';

const markAsReceived = (chat, message) => {
  const { data } = Axios.post(process.env.REACT_APP_MARK_AS_RECEIVED, {
    chat_uuid: chat,
    message_uuid: message
  });
  return data;
}

export default markAsReceived;