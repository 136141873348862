import React, { useContext } from 'react'
import { DropdownContext, UserContext } from 'context'
import { useParams } from 'react-router-dom'
import { UserSettings, UserImage } from 'components'
import styles from './styles.module.scss'


const UserDropdown = () => {
  const { user } = useContext(UserContext);
  const { setOpen } = useContext(DropdownContext)

  const handleClick = () => { setOpen(false) }

  return (
    <div>
      <div className={styles['user-box']}>
        <UserImage user={useParams().user} styling={styles['user-dropdown']}/>
        <div className={styles['user-info']}>
          <h4>{user?.name}</h4>
          <p className="text-muted">{user?.email}</p>
        </div>
      </div>
      <hr/>
      <div className={styles['list-container']}>
        <UserSettings handleClick={handleClick}/>
      </div>
    </div>
  )
}

export default UserDropdown