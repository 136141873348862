import React from 'react'
import CHART_TYPES from './chartTypes'

const PlotTypeSelect = ({setType}) => {
  return (
    <div className="row m-t-20">
      <div className="col">
        <label>Tipo de gráfica</label>
        <select className="form-control" 
          onChange={({target}) => {
            let find = CHART_TYPES.find((e) => e.label === target.value)
            setType(find.type)
          }}
          >
          { CHART_TYPES.map((e, i) => <option key={i}>{e.label}</option>)}
        </select>
      </div>
    </div>
  )
}

export default PlotTypeSelect