import Axios from "./config"
import { getToken } from "utils"

const { REACT_APP_LOGOUT_URL, REACT_APP_AUTH_TOKEN_KEY } = process.env

const logout = async() => {
  console.log(REACT_APP_LOGOUT_URL)
  const { data } = await Axios.post(REACT_APP_LOGOUT_URL, {
    token: getToken(REACT_APP_AUTH_TOKEN_KEY)
  })
  return data
}

export default logout