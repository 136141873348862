import Axios from './config'

const { REACT_APP_CHAT_UUID, REACT_APP_CHAT_STATUS, REACT_APP_CREATE_CHAT } = process.env

const createChat = async({ client }, token) => {
  const { data } = await Axios.post(REACT_APP_CREATE_CHAT, {
    uuid: client.uuid,
    client_id: client.id,
  })
  localStorage.setItem(REACT_APP_CHAT_UUID, data.chat.uuid)
  localStorage.setItem(REACT_APP_CHAT_STATUS, data.chat.status)
}

export default createChat