import React from 'react'
import { DropArea, ContextMenu } from 'components'
import ChatArea from '../ChatArea'
import ErrorCard from './ErrorCard'
import FileCard from './FileCard'
import Menu from './Menu'

const ChatBox = React.forwardRef(({pos, drag, handleDrop}, menu) => {
  return (
    <>
      <ContextMenu pos={pos} ref={menu}>
        <Menu/>
      </ContextMenu>
      <ErrorCard/>
      <DropArea isDragging={drag} handleDrop={handleDrop}/>
      <ChatArea/>
      <FileCard/>
    </>
  )
})

export default ChatBox