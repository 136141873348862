import React from 'react'
import { capitalize, getTextColor } from 'utils'
import stc from 'string-to-color'
import styles from './styles.module.scss'

const UserImage = ({ user, styling }) => {
    const color = stc(user[0].toUpperCase())
    // estilos para el fondo de la imagen
    const background = { backgroundColor: color }
    // obtiene el color del texto según el color de fondo
    const fontColor = { color: getTextColor(color)}
    
    return (
      <div className="chat-img">
        <div className={`rounded-circle text-center ${styles['user-image']} ${styling}`}
          style={background}>
          <span style={fontColor}>
            { capitalize(user).slice(0,2) }
          </span>
        </div>
      </div>
    )
  }
  
  export default UserImage