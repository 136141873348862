import React, { useContext } from 'react'
import { VisibleContext } from 'context'
import styles from './styles.module.scss'

const ContextMenu = React.forwardRef(({ children, pos }, ref) => {
  const { visible } = useContext(VisibleContext)
  return (
    <div className={`${styles["context-menu"]} ${visible ? styles.show: styles.hide}`} 
      style={{'--x-pos': `${pos.x}px`, '--y-pos': `${pos.y}px`}}
      ref={ref}
    >
      { children }
    </div>
  )
})

export default ContextMenu