import React, { useContext } from "react";
import { NotificationContext, DropdownContext } from "context";
import { deleteNotification } from "services";
import Notification from "../Notification";
import styles from "./styles.module.scss";

const NotificationArea = () => {
  const { notifications, setNotifications, loading, setNewNotification } = useContext(NotificationContext);
  const { setOpen } = useContext(DropdownContext)

  const handleNotificationClick = (id) => {
    setOpen(false);
    deleteNotification(id).then((data) => {
      setNotifications(prev => prev.filter(notification => notification.id !== id));
    });
  }

  return (
    <ul className={styles.container} onScrollCapture={() => setNewNotification(false)}>
      <li className={`card-header ${styles.header}`}>
        <h4>Notificaciones</h4>
      </li>
      <li>
        {loading ? (
          <div className="d-flex justify-content-center p-5">
            <div className={styles.loading}></div>
          </div>
        ) : (
          <div className={`message-center ${styles.body}`}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onClick={() => handleNotificationClick(notification.id)}
                />
              ))
            ) : (
              <div className="text-center p-5">
                <i className="fas fa-bell-slash"></i>
                <div className="title">Sin notificaciones</div>
              </div>
            )}
          </div>
        )}
      </li>
    </ul>
  );
};

export default NotificationArea;
