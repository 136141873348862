import Axios from "axios";

const { REACT_APP_LOGIN_URL } = process.env;

const login = async ({email, password}) => {
  try {
    const { data } = await Axios.post(REACT_APP_LOGIN_URL, {
      email: email.toLowerCase(),
      password: password,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default login;
