import React, { useContext } from 'react'
import { FileContext } from 'context'
import { InfoCard, FileCardContent } from 'components'

const FileCard = () => {
  const { file, setFile } = useContext(FileContext)
  return (
    <InfoCard show={file !== null} handleClose={() => setFile(null)}>
      { file && <FileCardContent/> }
    </InfoCard>
  )
}

export default FileCard