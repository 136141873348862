import React from 'react'
import User from '../User'
import Notifications from '../Notifications'

const NavBody = () => {
  return (
    <div className="d-flex navbar-collapse">
      <ul className="navbar-nav mr-auto mt-md-0"></ul>
      <ul className="navbar-nav my-lg-0">
        <li className="nav-item">
          <a className="nav-link" href={process.env.REACT_APP_DOCS_URL} title="Documentación" target="_blank">
            <i className="fas fa-book"></i>
          </a>
        </li>
        <Notifications/>
        <User/>
      </ul>
    </div>
  )
}

export default NavBody