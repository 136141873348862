import React, { useEffect, useRef } from 'react'
import styles from './styles.module.scss'

const Dropdown = ({ open, pos, children }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (open)
      ref.current.focus()
  }, [open])
  
  return (
    <>
      {
        open === null ? <></>
        : 
        <div
        ref={ref}
        tabIndex={1} 
        className={`card ${styles.dropdown} ${open ? styles.open: styles.close}`} 
        style={{'--pos': pos}}
        >
          { children }
        </div>
      }
    </>
  )
}

export default Dropdown