import { useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { getToken, setToken, removeToken } from 'utils'
import { isAuthenticated } from 'services'

const {
  REACT_APP_AUTH_TOKEN_KEY,
  REACT_APP_USER_INFO_KEY
} = process.env

const useAuthToken = () => {
  const navigate = useNavigate()
  const location = useLocation() 
  const { user: userParams } = useParams()

  useEffect(() => {
    let token = getToken(REACT_APP_AUTH_TOKEN_KEY)
    let currentURL = decodeURI(location.pathname)
    const user = JSON.parse(getToken(REACT_APP_USER_INFO_KEY))
    if (token !== null) {
      isAuthenticated().then((res) => {
        if (res?.isAuthenticated) {
          const { full_name_reversed } = res.user
          if (currentURL.includes('dashboard')) {
            if (userParams !== full_name_reversed)
              navigate(currentURL.replace(userParams, full_name_reversed))
            return
          }
          if (!user)
            setToken(REACT_APP_USER_INFO_KEY, JSON.stringify(res.user))
          navigate(`/dashboard/${full_name_reversed}`)
        } else {
          removeToken(REACT_APP_USER_INFO_KEY)
          removeToken(REACT_APP_AUTH_TOKEN_KEY)
          navigate('/')
        }
      })
    } 
    else {
      if (currentURL.includes('dashboard'))
        navigate('/')
    }
  }, [navigate, location])
}

export default useAuthToken