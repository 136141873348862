import Axios from "./config";

const closeChat = async (chatUuid) => {
  try {
    const response = await Axios.post("/api/chats/close", { chat_uuid: chatUuid });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export default closeChat;