import Axios from './config'

const { REACT_APP_CHAT_UUID, REACT_APP_CHAT_STATUS ,REACT_APP_GET_CHAT } = process.env

const getChat = async({ client }) => {
  const { data } = await Axios.get(REACT_APP_GET_CHAT + client.uuid);
  if (data.chat.uuid) {
    localStorage.setItem(REACT_APP_CHAT_UUID, data.chat.uuid)
    localStorage.setItem(REACT_APP_CHAT_STATUS, data.chat.status)
  }
}

export default getChat