import Axios from 'axios'
import { capitalize } from 'utils'

const { REACT_APP_REGISTER_URL } = process.env

const register = async({email, name, last_name, password, confirmation}) => {
  try {
    const { data } = await Axios.post(REACT_APP_REGISTER_URL, {
      email: email.toLowerCase(),
      name: capitalize(name),
      last_name: capitalize(last_name),
      password,
      password_confirmation: confirmation
    })
    return data
  } catch(error) {
    throw error.response.data
  }
}

export default register