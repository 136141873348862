import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useLastURLParam = ({param, condition}) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (condition && param) {
      const url = location.pathname.replace(encodeURI(param), '')
      navigate(url)
    }
  }, [condition])

}

export default useLastURLParam