import React from 'react'

const ErrorAlert = ({toggle, handler, children}) => {
  return (
    <div className={`alert alert-danger alert-dismissible fade ${toggle&&'show'} w-100`} role="alert"
      style={{'position': 'absolute', 'top': 0, zIndex: 20}}
    >
      <strong>Vaya!</strong> parece que hubo un error con lo siguiente:
      { children }
      <button type="button" className="close"
        data-dismiss="alert" aria-label="Close"
        onClick={handler}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

export default ErrorAlert