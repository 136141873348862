import React from 'react'
import { Link } from 'react-router-dom'
import { removeToken } from 'utils'
import styles from './styles.module.scss'
import { logout } from 'services'

const {
  REACT_APP_AUTH_TOKEN_KEY,
  REACT_APP_USER_INFO_KEY,
  REACT_APP_CHAT_UUID
} = process.env

const clearLocalStorage = () => {
  logout().then(res => {
    removeToken(REACT_APP_AUTH_TOKEN_KEY)
    removeToken(REACT_APP_USER_INFO_KEY)
    removeToken(REACT_APP_CHAT_UUID)
  })
}

const UserSettings = ({ handleClick }) => {
  return (
    <ul className={styles.list}>
      <li>
        <Link to="profile" className="dropdown-item"
          onClick={handleClick}
        >
          <i className="ti-user"></i> Mi perfil
        </Link>
      </li>
      <li>
        <Link to="settings" className="dropdown-item"
          onClick={handleClick}
        >
          <i className="ti-settings"></i> Ajustes
        </Link>
      </li>
      <li>
        <hr/>
      </li>
      <li>
        <Link to="/" className="dropdown-item" 
          onClick={clearLocalStorage}>
          <i className="fa fa-power-off"></i> Cerrar sesión
        </Link>
      </li>
    </ul>
  )
}

export default UserSettings