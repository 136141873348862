import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthToken, useSocket, useUserConnection, useValidSystem } from 'hooks'
import { UserContext, SocketContext, ServerContext } from 'context'
import { getToken } from 'utils'
import { Sidebar } from './components'
import { Navbar } from 'components'
import styles from './styles.module.scss'

const Dashboard = () => {
  const user = useUserConnection()
  const socket = useSocket(getToken(process.env.REACT_APP_AUTH_TOKEN_KEY))
  const { server, setServer } = useValidSystem()
  useAuthToken()
  
  return (
    <ServerContext.Provider value={{server, setServer}}>
      <UserContext.Provider value={{ user }}>
        <SocketContext.Provider value={{ socket }}>
          <Navbar/>
          <div className={styles['main-container']}>
              <Sidebar/>
              <div className={`container-fluid ${styles['chat-background']}`}>
                <Outlet/>
              </div>  
          </div>
        </SocketContext.Provider>
      </UserContext.Provider>
    </ServerContext.Provider>
  )
}

export default Dashboard