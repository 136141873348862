import React from 'react'

const PlotFilterSelect = ({options, children, setter}) => {
  return (
    <div className="col">
      <label>{children}</label>
      <select className="form-control"
        onChange={({target}) => setter(target.value)}
      >
        { options.map((e, i) => <option key={i}>{e}</option>) }
      </select>
    </div>
  )
}

export default PlotFilterSelect