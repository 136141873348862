import React from "react"
import { Link, useParams } from 'react-router-dom'
import styles from './styles.module.scss'

const Notification = ({ notification, ...props }) => {
  const { user } = useParams()
  const href = `/dashboard/${user}/system/${notification.title}`
  return (
    <Link to={href} className={styles.link} {...props}>
      <div className={styles.logo}>
        <i className={notification.icon}></i>
      </div>
      <div className={styles.content}>
        <h5>{notification.title}</h5>
        <p>{notification.message}</p>
        <p>{notification.time}</p>
      </div>
    </Link>
  );
};

export default Notification;
