import React, { useState } from 'react'
import { UserImage } from 'components'
import { useParams } from 'react-router-dom'
import UserDropdown from '../UserDropdown'
import styles from './styles.module.scss'
import { handleChildrenBlur } from 'utils'

const UserCard = () => {
  const [ toggle, setToggle ] = useState(false)
  const user = useParams().user ?? 'Default User'

  return (
    <div className={`user-profile ${styles.userBg}`}>
      <div className={styles['user-overflow']}>
        <div className={`profile-img ${styles['narrow-image']}`}>
          <UserImage user={user} styling={styles['user-image']}/>
        </div>
      </div>
      <button 
        className={styles["dropdown-toggle"]} 
        onBlur={(evt) => handleChildrenBlur(evt, setToggle)}
        onClick={() => setToggle(prev => !prev)}
        >
        <div>
          { user }
        </div>
        <UserDropdown show={toggle}/>
      </button>
    </div>
  )
}

export default UserCard