import React, { useContext } from "react";
import { OverlayContext, FileContext, VisibleContext } from "context";
import { getFileExtension } from "utils";
import styles from "./styles.module.scss";

const FileMessage = ({ file, msg }) => {
  const { setOverlay } = useContext(OverlayContext);
  const { setVisible } = useContext(VisibleContext);
  const { setFileMessage } = useContext(FileContext);
  const extension = getFileExtension(file?.name);

  const handleClick = () => {
    setFileMessage(file)
    setOverlay(true);
  };

  return (
    <>
      <div className={styles['file-message']}  
        onClick={() => handleClick()}
        onContextMenu={() => setVisible(true)}
      >
        <div className={styles['icon-container']}>
          <i className={`mdi mdi-file ${styles.icon}`}/>
          <span>
            { extension.slice(0, 4) }
            { extension.length > 4 ? '...' : '' }
          </span>
        </div>
        <div className="mx-auto col-10">
          <p>{file?.name}</p>  
        </div>
      </div>
      {
        msg && <p className={styles.msg}>{msg}</p>
      }
    </>
  );
};

export default FileMessage;
