import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToggleContext, SocketContext, UserContext } from "context";
import { searchData } from "utils";
import { joinChat, markAsSeen } from "services";
import { useSystemsChats } from 'hooks'
import { decrypt } from 'utils';
import ClientMessageCard from "./ClientMessageCard"
import styles from "./styles.module.scss";

const ChannelBar = () => {
  const { user } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  const { toggle, setClientChat, setClient, clientChat} = useContext(ToggleContext);
  const [search, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const chats = useSystemsChats({setClients})

  useEffect(() => {
    if (chats && user) {
      socket?.private('notifications.user.' + user.id)
        .notification((data) => {
          if (data.type == 'new-chat-message') {
            const newClient = clients.find((client) => client.uuid === data.client.uuid);
            if (!newClient) {
              data.client.lastMessage = data.lastMessage;
              data.client.lastMessage.msg = decrypt(data.client.lastMessage.msg);
              data.client.unreadMessages = 1;
              setClients([data.client, ...clients]);
              return;
            }
            const newClients = clients.map((client) => {
              if (client.uuid == data.client.uuid) {
                client.lastMessage = data.lastMessage;
                client.lastMessage.msg = decrypt(client.lastMessage.msg)
                if (user.name != data.lastMessage.user) {
                  client.unreadMessages++;
                } 
              } 
              return client;
            }).sort((x, y) => {
              return new Date(x.lastMessage?.timestamp) < new Date(y.lastMessage?.timestamp) ? 1 : -1;
            }); 
            setClients(newClients);
          } else if (data.type == 'seen-messages') {
            const newClients = clients.map((client) => {
              if (client.uuid == data.client.uuid) {
                if (user.name != data.lastMessageOwner) {
                  client.unreadMessages = 0;
                }
              } 
              return client;
            }).sort((x, y) => {
              return new Date(x.lastMessage?.timestamp) < new Date(y.lastMessage?.timestamp) ? 1 : -1;
            });
            setClients(newClients);
          } else if (data.type == 'chat-closed') {
            const newClients = clients.filter((client) => client.uuid != data.uuid);
            socket.leave(clientChat);
            setClient(null);
            setClientChat(null);
            setClients(newClients);
            navigate(location.pathname);
          }
        })
    }
  }, [socket, chats]);

  const handleClientClick = (client) => {
    if (!decodeURI(location.pathname).includes(client.name)) {
      socket.leave(clientChat);
      setClientChat('chat.' + client.uuid);
      setClient(client);
      navigate(`${client.name}`);
      joinChat('chat.'+ client.uuid).then((data) => {
        markAsSeen(data.chat.uuid);
      })
    }
  };

  const renderUsers = () => {
    let filter = searchData(clients, search);
    if (filter.length === 0)
      return <p className="text-center p-t-10">No hay coincidencias</p>;
    return filter.map((e) => {
      return <ClientMessageCard key={e.uuid} client={e} onClick={() => handleClientClick(e)}/>
    });
  };

  return (
    <div
      className={`chat-left-aside h-100 ${styles.container} ${
        toggle && styles.toggle
      }`}
    >
      <div className="chat-left-inner">
        <div className="form-material">
          <input
            style={{height: 71}}
            className="form-control p-20"
            type="text"
            placeholder="Buscar cliente..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="message-box contact-box">
          {renderUsers()}
        </div>
      </div>
    </div>
  );
};

export default ChannelBar;
