import fileDownload from 'js-file-download'
import getFileExtension from './getFileExtension'

const downloadFile = async base64 => {
  const data = await fetch(base64)
  const blob = await data.blob()
  const ext = getFileExtension(blob.type, '/')

  // crea y descarga un archivo con el nombre 'file'
  fileDownload(blob, `file.${ext}`)
}

export default downloadFile