import React, { useState, useEffect, useContext } from "react";
import NavButton from "../NavButton";
import NotificationArea from "../NotificationArea";
import { NotificationContext, SocketContext, UserContext } from "context";
import { getNotifications } from "services";
import styles from "./styles.module.scss";

export default function Notifications() {
  const { user } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePushNotification = (notification) => {
    if (!("Notification" in window)) {
      return;
    }
    
    const options = {
      body: `${notification.message} - ${notification.time}`,
      icon: '/assets/images/light-icon.png',
    }

    const showNotification = () => {
      new Notification(notification.title, options)
    }

    if (Notification.permission === "granted") {
      showNotification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification();
        }
      });
    }
  };

  useEffect(() => {
    getNotifications().then((data) => {
      setNotifications(data.notifications);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading && user) {
      socket?.private('notifications.user.' + user.id).notification((data) => {
        if (data.type == 'new-client-chat') {
          setNewNotification(true);
          setNotifications((notifications) => [data, ...notifications]);
          //Enable the HTTPS protocol to use the notification feature
          handlePushNotification(data);
        }
      });
    }
  }, [socket, loading]);

  useEffect(() => {
    if (notifications.length > 0) {
      setNewNotification(true);
    } else {
        setNewNotification(false);
    }
  }, [notifications.length]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        newNotification,
        setNewNotification,
        loading
      }}
    >
      <NavButton dropdown={<NotificationArea/>} pos={1}>
        <i className={`fas fa-bell ${styles.icon}`}>
          {newNotification ? <span className={styles.heartbeat}></span> : <></>}
        </i>
      </NavButton>
    </NotificationContext.Provider>
  );
}
