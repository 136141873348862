import React from 'react'

const ImagePreview = ({ preview }) => {
  return (
    <img 
      onMouseDown={(e) => e.stopPropagation()}
      src={preview}
      width='100%'
      alt='Preview' 
    />
  )
}

export default ImagePreview