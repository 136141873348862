const getBase64 = file => {
  var reader = new window.FileReader()
  reader.readAsDataURL(file)

  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export default getBase64