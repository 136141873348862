import Axios from "axios";
import { setToken, getToken } from "utils";
import { createChat, getChat } from "services";

const { REACT_APP_AUTH_TOKEN_KEY, REACT_APP_CLIENT_CONNECTION } = process.env;

const clientConnection = async (params) => {
  try {
    let userInfo = JSON.parse(params);
    userInfo.hasToken = getToken(REACT_APP_AUTH_TOKEN_KEY) ? true : false;
    const user = userInfo;
    const { data } = await Axios.post(REACT_APP_CLIENT_CONNECTION, user);
    if (data.access_token) {
      const token = `${data.token_type} ${data.access_token}`;
      setToken(REACT_APP_AUTH_TOKEN_KEY, token);
      await createChat(data, token);
    } else {
      await getChat(data);
    }
    return user;
  } catch (err) {
    console.error(err);
  }
};

export default clientConnection;
