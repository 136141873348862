import React, { useState, useEffect, useContext } from 'react'
import { OverlayContext, FileContext } from 'context'
import { getFileExtension, getBase64 } from 'utils'
import ImagePreview from './ImagePreview'
import ObjectPreview from './ObjectPreview'
import styles from './styles.module.scss'

const FileReader = () => {
  const { overlay, setOverlay } = useContext(OverlayContext)
  const { file, fileMessage } = useContext(FileContext)
  const [ preview, setPreview ] = useState(null)

  useEffect(() => {
    if (file !== null) {
      getBase64(file)
      .then(res => setPreview(res))
    }
    else if (fileMessage !== null) {
      setPreview(fileMessage.url)
    }

  }, [file, fileMessage])

  return (
    <div className={`${styles.overlay} ${!overlay && styles.close}`}
      onMouseDown={() => setOverlay(false)}
    >
      <i className={`mdi mdi-close ${styles.icon}`}/>
      <div className={styles.content}>
        {
          preview && (file || fileMessage) &&
          getFileExtension(file ? file.name : fileMessage.name) === 'pdf' 
          ? <ObjectPreview preview={preview} type='application/pdf'/>
          : <ImagePreview preview={preview}/>
        }
      </div>
    </div>
  )
}

export default FileReader