import React, { useContext } from "react";
import { UserImage, NotificationBadge } from "components";
import { UserContext, ToggleContext } from "context";
import styles from "./styles.module.scss";

const ClientMessageCard = ({ client, ...props }) => {
  const { user } = useContext(UserContext);
  const { lastMessage } = client;
  const { clientChat } = useContext(ToggleContext);

  return (
    <div
      className={`message-widget contact-widget ${
        clientChat == "chat." + client.uuid ? styles["client-btn"] : ""
      }`}
      style={{ cursor: "pointer" }}
    >
      {user ? (
        <a {...props}>
          <div className="user-img col-1 p-0">
            <UserImage user={client.name} styling={styles["user-img"]} />
          </div>
          <div className="mail-contnet col-auto">
            <h6>{client.name}</h6>
            {lastMessage ? (
              <>
                <span className="mail-desc">
                  {(lastMessage.user == user.name
                    ? "Tú: "
                    : lastMessage.user.split(" ")[0] + ": ") + lastMessage.msg}
                </span>
                <span className="time d-flex justify-content-end align-items-end">
                  <small>{lastMessage.time}</small>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
          {client.unreadMessages ? (
            <NotificationBadge info={client.unreadMessages} />
          ) : (
            ""
          )}
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export default ClientMessageCard;
