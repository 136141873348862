import Axios from './config';

const isAuthenticated = async () => {
    try {
        const { data } = await Axios.get('/api/user/check');
        return data;
    } catch (err) {
        return err
    }
}

export default isAuthenticated;