import React from "react";
import styles from "./styles.module.scss";

const Spinner = ({text}) => {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className={styles.spinner}></div>
      </div>
      {text ? <p className="p-1">{text}</p> : ''}
    </div>
  )
};

export default Spinner;
