import React from 'react'
import background from 'assets/images/background/login.jpg'

const LazyBackground = ({children, sidebar}) => {
  return (
    <div className={`login-register ${sidebar&&'login-sidebar'}`} 
      style={{ backgroundImage: `url(${background})`}}>
      { children}  
    </div>
  )
}

export default LazyBackground