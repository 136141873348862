import React, { useState } from 'react'
import { StyledButton, Dropdown } from 'components'
import { handleChildrenBlur } from 'utils'
import { DropdownContext } from 'context'
import styles from './styles.module.scss'

const NavButton = ({ dropdown, children, pos }) => {
  const [ open, setOpen ] = useState(null)

  const toggleDropdown = () => { 
    if (open === null)
      setOpen(true)
    else
      setOpen(prev => !prev) 
  }

  return (
    <li className="nav-item dropdown"
      onBlur={(e) => handleChildrenBlur(e, setOpen)}
    >
      <StyledButton 
        onClick={toggleDropdown}
        styling={`nav-link ${styles['nav-btn']}`}
      >
        { children }
      </StyledButton>
      <DropdownContext.Provider value={{open, setOpen}}>
        <Dropdown open={open} pos={pos}>
          { dropdown }
        </Dropdown>
      </DropdownContext.Provider>
    </li>
  )
}

export default NavButton;