import React from 'react'

const ObjectPreview = ({ preview, type }) => {
  return (
    <object
      onMouseDown={(e) => e.stopPropagation()}
      aria-label="Preview"
      data={preview}
      type={type}
      width='100%'
      height='100%'
    />
  )
}

export default ObjectPreview