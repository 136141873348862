import React, { useContext } from 'react'
import { FileContext, ErrorContext } from 'context'
import styles from './styles.module.scss'
import { validateFileType } from 'utils'

const FilePicker = ({disabled}) => {
  const { setFile } = useContext(FileContext)
  const { setError } = useContext(ErrorContext)

  const handleFileInput = ({target}) => {
    if (target.files[0]) {
      const { type } = target.files[0]
      if (validateFileType(type))
        setFile(target.files[0])
      else {
        setError(true)
        setTimeout(() => setError(false), 3000)
      }
    }
  }

  return (
    <div className={styles['file-container']}>
      <label htmlFor="file-input">
        <i className={`mdi mdi-file ${disabled ? styles['icon-disabled']:styles.icon}`}/>
      </label>
      <input disabled={disabled} id="file-input" type="file" 
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        value='' onChange={handleFileInput}
      />
    </div>
  )
}

export default FilePicker