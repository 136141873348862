import React from 'react'
import styles from './styles.module.scss'

const InfoCard = ({ show, styling='alert-info', handleClose, children }) => {
  return (
    <div className={`alert ${styling} ${styles.container} ${show && styles.show}`}>
      <button className={`close ${styles.close}`}
        onClick={handleClose}
      >
        <span>&times;</span>
      </button>
      { children }
    </div>
  )
}

export default InfoCard