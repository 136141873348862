import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TabItem from './TabItem'

const TabNavigator = ({tabs}) => {
  const [ active, setActive ] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const lastParam = 'analytics'
  const urlLength = location.pathname.indexOf(lastParam) + lastParam.length

  useEffect(() => {
    if (active !== null) {
      const url = location.pathname.slice(0, urlLength)
      if (location.pathname !== `${url}/${active}`)
      navigate(`${url}/${active}`)
    }
  }, [active])
    
  useEffect(() => {
    const lastUrl = location.pathname.slice(urlLength + 1)
    if (lastUrl !== active && lastUrl)
      setActive(lastUrl)
  }, [location])

  return (
    <nav className='w-100'>
      <ul className='row nav nav-tabs'>
        { 
          tabs.map((e, i) => {
            return (
              <TabItem key={i} active={active} setActive={setActive}>
                {e}
              </TabItem>
            )
          })
        }
      </ul>
    </nav>
  )
}

export default TabNavigator