import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CardContainer } from 'components'
import { ServerContext } from 'context'
import UserHeader from './UserHeader'
import UserInfo from './UserInfo'
import styles from './styles.module.scss'
import { getToken } from 'utils'

const USER_INFO = process.env.REACT_APP_USER_INFO_KEY

const Profile = () => {
  const [user, setUser] = useState(null)
  const { pathname } = useLocation()
  const url = pathname.replace(/[^/]*$/, 'settings')
  const { setServer } = useContext(ServerContext)
  
  useEffect(() => {
    setUser(JSON.parse(getToken(USER_INFO)))
    setServer(undefined)
  }, []);

  return (
    <CardContainer styling={styles.margin}>
      <div className={styles.container}>
        {user && <UserHeader {...user}/>}
        <div className="card-body p-t-2"> 
          <hr/>
          {user && <UserInfo {...user}/> }
        </div>
        <div className="card-footer p-20">
          <center>
            <Link to={url} className="btn btn-warning btn-rounded">
              Editar Perfil
            </Link>
          </center>
        </div>
      </div>
    </CardContainer>
  )
}

export default Profile