import React from 'react'
import styles from './styles.module.scss'

const DropArea = ({ isDragging, handleDrop }) => {
  return (
    <div className={`${styles.droppable} ${isDragging && styles.show}`}
      onDrop={handleDrop}
    >
      <div className={styles['droppable-wrapper']}>
        <i className={`mdi mdi-file ${styles.icon}`}/>
        <h2>
          Suelta tus archivos aquí
        </h2>
        <p>para enviarlos</p>
      </div>
    </div>
  )
}

export default DropArea