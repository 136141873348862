import React, { useContext } from 'react'
import { ErrorContext } from 'context'
import { InfoCard } from 'components'

const ErrorCard = () => {
  const { error, setError } = useContext(ErrorContext)

  return (
    <InfoCard show={error}
      handleClose={() => setError(false)}
      styling='alert-danger'
    >
      <p>
        <strong>Error de formato</strong>
        <br/>
        Ingrese imagenes o archivos pdf.
      </p>
    </InfoCard>
  )
}

export default ErrorCard