import React, { useState, lazy, Suspense } from 'react'
import { setToken } from 'utils'
import { register, login } from 'services'
import { useAuthToken } from 'hooks'
import { PasswordInput, ErrorAlert, Spinner } from 'components'
import { Link, useNavigate } from 'react-router-dom'
const LazyBackground = lazy(() => import('components/LazyBackground'))

const { REACT_APP_AUTH_TOKEN_KEY, REACT_APP_USER_INFO_KEY } = process.env

const Signup = () => {
  const [ errors, setErrors ] = useState({})
  const [ toggle, setToggle ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ form, setForm ] = useState({
    name: '',
    last_name: '',
    password: '',
    confirmation: '',
    email: ''
  })

  const navigate = useNavigate()
  useAuthToken() // revisa si existe un token para saltarse esta página

  // Crea los tokens del usuario e inicia sesión
  const handleNavigation = (data) => {
    const { user } = data
    setToken(REACT_APP_AUTH_TOKEN_KEY, `${data.token_type} ${data.access_token}`)
    setToken(REACT_APP_USER_INFO_KEY, JSON.stringify(user))
    navigate(`/dashboard/${user.full_name_reversed}/system`)
  }

  // Feedback cuando hay errores del formulario
  const handleError = ({errors}) => {
    setErrors(errors)
    setToggle(true)
    setTimeout(() => {
      setErrors({})
      setToggle(false)
    }, 3000)
  }

  // Petición al servidor
  const handleSubmit = evt => {
    evt.preventDefault()
    setLoading(true)
    register(form).then((res) => {
      handleNavigation(res)
    }).catch((res) => {
      setLoading(false)
      handleError(res)
    })
  }

  // Maneja los cambios del formulario
  const handleChange = ({target}) => {
    const { value, name } = target
    setForm(prev => ({...prev, [name]: value}))
  }

  return (
    <Suspense fallback={
      <div className='mt-auto mb-auto'>
        <Spinner/>
      </div>
    }>
      <LazyBackground>
        <ErrorAlert toggle={toggle} handler={() => setToggle(prev => !prev)}>
          <ul> 
            {
              Object.keys(errors).map((e, i) => {
                return(
                  <li key={i}>
                    <strong>{e}: </strong>{errors[e][0]}
                  </li>
                )   
              })
            }
          </ul>
        </ErrorAlert>
        <div className="login-box card">
          <div className="card-body">
            <form onSubmit={handleSubmit} 
              className="form-horizontal form-material" id="loginform"
            >
              <h3 className="box-title m-b-20">Crear cuenta</h3>
              <div className="form-group">
                <input required type="email" className="form-control"
                  placeholder="Correo electrónico *" value={form.email}
                  onChange={handleChange} name="email"
                />
              </div>
              <div className="form-group">
                <input className="form-control" type="text"
                  required value={form.name}
                  placeholder="Nombre(s) *" name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input className="form-control" type="text"
                  required value={form.last_name}
                  placeholder="Apellido(s) *" name="last_name" 
                  onChange={handleChange}
                />
              </div>
              <PasswordInput value={form.password} handler={handleChange}
                placeholder='Contraseña *' name='password' required/>
              <PasswordInput value={form.confirmation} handler={handleChange}
                placeholder='confirmationar contraseña *' name='confirmation'
                required
              />
              <div className="form-group">
                <Link align="right" to={`/`}>
                    Regresar a inicio de sesión
                </Link>
              </div>
              <button className={`${toggle ? 'btn-danger': 'btn-info'} btn-lg btn-block`} type="submit">
                {
                  toggle ? "Error":
                  loading ? "Cargando..." : "Crear cuenta"
                }
              </button>
            </form>
          </div>
        </div>
      </LazyBackground>
    </Suspense>
  )
}

export default Signup
