import React from 'react'
import styles from './styles.module.scss'

const CardContainer = ({ children, styling }) => {
  return (
    <div className={`chat-main-box ${styles.container} ${styling}`}>
      <div className={`flex-row card m-b-0 ${styles.container}`}>
        { children }
      </div>
    </div>
  )
}

export default CardContainer