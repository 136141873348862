import React from 'react'
import styles from './styles.module.scss'

const ToggleButton = ({toggle, ...props}) => {
  return (
    <button className={`${styles['toggle-icon']} ${toggle && styles.animate}`}
      {...props}
    >
      <div className={styles['line-top']}/>
      <div className={styles['line-middle']}/>
      <div className={styles['line-bottom']}/>
    </button>
  )
}

export default ToggleButton