import React, { useContext } from "react";
import { ChatContext } from "context";
import MenuOptions from "./MenuOptions";
import { useIsClient } from "hooks";

const { REACT_APP_CHAT_UUID } = process.env;

const ChatHeader = ({ title, name, email }) => {
  const chat = localStorage.getItem(REACT_APP_CHAT_UUID);
  const { chatStatus } = useContext(ChatContext);
  const status = {'open': 'En espera', 'attending': 'En atención'}
  const statusColor = {'open': 'warning', 'attending': 'success'}
  const { isClient } = useIsClient();

  return (
    <div className="chat-main-header">
      <div className="p-2 b-b">
        <h3 className={name && email ? "m-b-0" : "box-title"}>
          {title ? title : "ASSIST"} {name ? ` - ${name}` : ""}
        </h3>
        {email && (
          <small className="text-muted">{`Contacto: ${email}`}</small>
        )}
        {/* Uncomment this to enable chatbot features */}
        {/* {(chatStatus == "closed" && chat && isClient) && <MenuOptions chat={chat} />} */}
        {chat && chatStatus != "closed" && <div className={`badge badge-${statusColor[chatStatus]}`}>{status[chatStatus]}</div>}
      </div>
    </div>
  );
};

export default ChatHeader;
