import Axios from "./config";

const { REACT_APP_GET_SYSTEM_CHATS } = process.env;

const getSystemChats = async (system) => {
  const { data } = await Axios.get(`${REACT_APP_GET_SYSTEM_CHATS}${system}`);
  return data;
};

export default getSystemChats;
