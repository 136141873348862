import { useState, useEffect } from 'react'
import { getSystems } from 'services'

const useSystems = ({append}) => {
  const [ systems, setSystems ] = useState([])

  useEffect(() => {
    getSystems().then(res => {
      setSystems([append,...res.systems.map(e => e.name)])
    })
  }, [])

  return systems
}

export default useSystems