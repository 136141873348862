import React, { useState, useContext, useEffect, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { getTime, encrypt } from 'utils'
import { nanoid } from 'nanoid'
import { UserContext, FileContext, ToggleContext, ChatContext } from 'context'
import { markAsSeen, postMessage } from 'services'
import FilePicker from './FilePicker'
import { useIsClient } from 'hooks'

const { REACT_APP_CHAT_UUID } = process.env

const ChatInput = () => {
  const { file, setFile } = useContext(FileContext)
  const { user } = useContext(UserContext)
  const { clientChat } = useContext(ToggleContext)
  const { chatStatus } = useContext(ChatContext)
  const [ message, setMessage ] = useState("")
  const [ disabled, setDisabled ] = useState(true)
  const focus = useRef(null)
  const { usr } = useParams()
  const location = useLocation()
  const { isClient } = useIsClient()

  useEffect(() => {
    if (location.pathname.includes('widget'))
      setDisabled(false)
    else
      setDisabled(usr === undefined)
  }, [usr])

  const handleSubmit = evt => {
    evt.preventDefault()
    // creamos la estructura del mensaje
    const chat_uuid = localStorage.getItem(REACT_APP_CHAT_UUID)
    let newMsg = {
      id: nanoid(),
      chat_uuid: chat_uuid ? chat_uuid : clientChat,
      type: '',
      user,
      time: getTime(),
      message: ''
    }
    
    if (file) {
      newMsg.id = nanoid()
      newMsg.type = 'file'
      newMsg.message = encrypt(message)
      newMsg.file = file
      postMessage(newMsg)
    } 
    
    if (message !== "" && !file) {
      newMsg.id = nanoid()
      newMsg.type = 'text'
      newMsg.message = encrypt(message)
      postMessage(newMsg)
    }

    setMessage("")
    setFile(null)
    focus.current.focus()
  }

  return (
    <div className="card-body b-t">
      <form onSubmit={handleSubmit} className="d-flex w-100">
        {!clientChat && chatStatus != 'closed' && <FilePicker disabled={disabled}/>}
        {clientChat && isClient && <FilePicker disabled={disabled}/>}
        <textarea
          disabled={disabled}
          ref={focus}
          value={message}
          onChange={({target}) => setMessage(target.value)} 
          onFocus={() => clientChat ? markAsSeen(clientChat) : markAsSeen(localStorage.getItem(process.env.REACT_APP_CHAT_UUID))}
          placeholder="Escriba su mensaje aquí" 
          className="form-control b-0"/>
        <button disabled={disabled} type="submit" 
          className="btn btn-info btn-circle btn-lg ml-3"
          style={{lineHeight: '100%'}}>
          <i className="fas fa-paper-plane"/>
        </button>
      </form>
    </div>
  )
}

export default ChatInput