import { useState, useEffect, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ChatContext, SocketContext } from 'context'
import { getChatMessages, markAsReceived, chatBotRequest } from 'services'
import { decrypt, decryptAll } from 'utils'
import { useChannel, useIsClient } from 'hooks'
import debounce from 'just-debounce-it'

const useMessages = ({ bottom, load, setLoad }) => {
  const { usr } = useParams()
  const { channel } = useChannel({usr})
  const { socket } = useContext(SocketContext)
  const { chatStatus, setChatStatus } = useContext(ChatContext)
  const [ msgs, setMsgs ] = useState([])
  const [ batchMsgs, setBatchMsgs ] = useState([])
  const [ page, setPage ] = useState(2)
  const [ loadingBatch, setLoadingBatch ] = useState(false)
  const { isClient } = useIsClient()
  const lastMsgRef = useRef(null)

  const fetchMessages = () => {
    setLoad(false)
    getChatMessages(channel).then((data) => {
      if (data.messages) {
        const messages = decryptAll(data.messages.reverse()) 

        if (msgs.length === 0) 
          setMsgs(prev => [...messages, ...prev])

        if (data.unreadMessages) {
          window.top.postMessage('unreadMessages', '*');
        }
      }
      // Uncomment this to enable the chatbot
      // if (chatStatus == 'closed') {
      //   chatBotRequest('greet', channel).then((message) => {
      //     setMsgs(prev => [...prev, message])
      //   })
      // }
    })
  }

  const fetchBatchMessages = () => {
    setLoad(false)
    getChatMessages(channel, page).then((data) => {
      if (data.messages) {
        const messages = decryptAll(data.messages.reverse())
        if (loadingBatch) {
          if (batchMsgs.length !== 0) {
            setMsgs(prev => [...batchMsgs, ...prev])
          }
          setBatchMsgs(messages)
        }  
      }
      setLoad(false)
      setLoadingBatch(false)
    })
    setPage(prev => prev + 1)
  }

  const debounceFetch = debounce(fetchBatchMessages, 500)

  // Obtiene los mensajes en caso de que el canal cambie o se solicite un paginado
  useEffect(() => {
    if (load) {
      setLoadingBatch(true)
    }
  }, [load])

  useEffect(() => {
    if (loadingBatch) 
      debounceFetch()
  }, [loadingBatch])

  useEffect(() => {
    if (channel) {
      setMsgs([])
      setBatchMsgs([])
      setPage(2)
      if (isClient) {
        fetchMessages()
      }
    }
  }, [channel])

  // Lleva el scroll hasta el último mensaje recibido en tiempo real
  useEffect(() => {
    // evitamos que el scroll se vaya hasta abajo si se carga una nueva página
    if (!loadingBatch) {
      bottom.current.scrollIntoView({ behavior: "smooth" });
    }

    if (msgs.length == 0 && !isClient) {
      fetchMessages()
    }
  }, [msgs])

  // Lleva el scroll hasta el último mensaje recibido antes del batch
  useEffect(() => {
    lastMsgRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
  }, [batchMsgs])
  
  // Obtiene los mensajes en tiempo real
  useEffect(() => {
    if (socket?.connector.channels['private-' + channel]) {
      socket.leave(channel);
    }
    socket?.private(channel)
    .listen(".MessageSent", (data) => {
      const msg = {
        id: data.id,
        user: data.user,
        time: data.time,
        msg:  decrypt(data.msg),
        type: data.type,
        file: data.file ? data.file : null
      }
      setMsgs(prev => [...prev, msg])
      setLoadingBatch(false)
      window.top.postMessage('unreadMessages', '*');
      markAsReceived(channel, msg.id);
    })
    .listen('.MessageReceived', (data) => {
      setMsgs(prev => prev.map(msg => {
        if (msg.id === data.id) {
          return {
            ...msg,
            status: data.status
          }
        }
        return msg
      }))
    })
    .listen('.MessageSeen', (data) => {
      setMsgs(prev => prev.map(msg => {
        if (msg.status == 'received' && msg.user != data.user) {
          return {
            ...msg,
            status: 'seen'
          }
        }
        return msg
      }))
    })
    .listen('.ChatStatusChanged', (data) => {
      setChatStatus(data.status)
    })
  }, [socket, channel])

  return { msgs, batchMsgs, lastMsgRef }
}

export default useMessages