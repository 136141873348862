import React, { useContext } from "react";
import { UserContext } from "context";
import { UserImage } from "components";
import FileMessage from "../FileMessage";
import ChatBotLogo from "assets/images/chatbot.png";
import styles from './styles.module.scss';

const CHATBOT = process.env.REACT_APP_CHATBOT_NAME;

const Message = ({ body, prev, children }) => {
  const { time, type, user } = body;
  const { user: currentUser } = useContext(UserContext);
  const reverse = currentUser.name === user;
  const showImg = prev === user;
  const msgBg = reverse ? "bg-light-inverse" : "bg-light-info";

	const getMsgStatus = (state) => {
		switch (state) {
			case 'send':
				return <i className='mdi mdi-check'/>
			case 'received':
				return <i className='mdi mdi-check-all'/>
			case 'seen':
				return <i className={`mdi mdi-check-all ${styles.seen}`}/>
			default:
				return <i className='mdi mdi-clock'/>			
		}
	}

  return (
    <li className={reverse ? "reverse" : "m-t-20"}>
      <div>
        {!reverse &&
          (user == CHATBOT ? (
            <div className="chat-img">
              <div className="rounded-circle">
                <img src={ChatBotLogo} alt="CB" />
              </div>
            </div>
          ) : (
            <UserImage user={user} />
          ))}
        <div className="chat-content">
          {!showImg && <h5>{user}</h5>}
          <div
            className={`box ${!showImg ? "m-t-20" : ""} ${msgBg} ${type === 'file'? 'p-0': ''}`}
            style={{ maxWidth: "20ch" }}
          >
            {type === "file" ? (
              <FileMessage file={body.file} msg={body.msg} />
            ) : type === "link" ? (
              <a href={body.msg} target="_blank" rel="">
                {body.msg}
              </a>
            ) : (
              children
            )}
          </div>
        </div>
        {reverse && <UserImage user={user} />}
      </div>
      <div className={styles.time}>{time}</div>
      { reverse && getMsgStatus(body.status) }
    </li>
  );
};

export default Message;
