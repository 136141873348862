import React from 'react'

const StyledInput = ({label, children}) => {
  return (
    <>
    {
      label ?
      <div className="form-group">
        <label className="col-md-12">{label}</label>
        <div className="col-md-12">
        { children }
        </div>
      </div>
      
      :
      <div className="form-group m-t-40">
        {children}
      </div>
    }
    </>
  )
}

export default StyledInput