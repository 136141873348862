import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PlotTypeSelect from './PlotTypeSelect'
import PlotFilterSelect from './PlotFilterSelect'
import CHART_TYPES from './chartTypes'
import { getStats } from 'services'
import { useSystems } from 'hooks'
import { Chart } from 'react-chartjs-2'
import { TIME_FILTER, getChartData } from 'utils'
import 'chart.js/auto'

const Content = () => {
  const ALL = 'Todos los sistemas'
  const [ type, setType ] = useState(CHART_TYPES[0].type)
  const [ data, setData ] = useState(null)
  const [ chartData, setChartData ] = useState(null)
  const [ sysFilter, setSysFilter ] = useState(ALL)
  const [ timeFilter, setTimeFilter ] = useState(TIME_FILTER[0])
  const systems = useSystems({append: ALL})
  const location = useLocation()

  useEffect(() => {
    const system = sysFilter !== ALL ? sysFilter: ''
    if (location.pathname.includes('systems'))
      getStats({type: 'chats', system}).then((res) => setChartData(res))
    else
      getStats({type: 'tickets', system}).then((res) => setChartData(res))
  }, [sysFilter, location])

  useEffect(() => {
    if (chartData)
      setData(getChartData(chartData, timeFilter))
  }, [timeFilter, chartData])

  return (
    <div className="container p-20" style={{maxWidth: '850px'}}>
      { data && <Chart type={type} data={data}/> }
      <div className="from group">
        <PlotTypeSelect setType={setType}/>
        <div className="row m-t-20">
          <PlotFilterSelect options={systems} setter={setSysFilter}>
            Sistema
          </PlotFilterSelect>
          <PlotFilterSelect options={TIME_FILTER} setter={setTimeFilter}>
            Período
          </PlotFilterSelect>
        </div>
      </div>
    </div>
  )
}

export default Content