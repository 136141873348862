import React, { useState, useEffect } from 'react'
import { validateCSRF } from 'services'
import { OverlayContext, FileContext } from 'context'
import { FileReader } from 'components'
import { 
  Widget, Login, Dashboard,
  ErrorPage, Analytics, Signup,
  Profile, Settings, ClientApp
} from './pages'
import { 
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

const App = () => {
  const [ overlay, setOverlay ] = useState(null)
  const [ file, setFile ] = useState(null)
  const [ fileMessage, setFileMessage ] = useState(null)

  useEffect(() => {
    validateCSRF()
  }, [])

  return (
    <div className="d-flex flex-column" style={{height: '100vh'}}> 
      <OverlayContext.Provider value={{overlay, setOverlay}}>
        <FileContext.Provider value={{file, setFile, fileMessage, setFileMessage}}>
          {
            overlay !== null ?
            <FileReader/> : <></>
          }
          <BrowserRouter>
            <Routes>
              <Route index element={<Login/>}/>
              <Route path='/dashboard/:user' element={<Dashboard/>}>
                <Route index element={<Profile/>}/>
                <Route path='profile' element={<Profile/>}/>
                <Route path='settings' element={<Settings/>}/>
                <Route path='analytics/systems' element={<Analytics/>}/>
                <Route path='analytics/tickets' element={<Analytics/>}/>
                <Route path='system'>
                  <Route index element={<Profile/>}/>
                  <Route path=':sys' element={<Widget showChannel={true}/>}>
                    <Route path=':usr' element={<Widget showChannel={true}/>}/>
                  </Route>
                </Route>
              </Route>
              <Route path="/widget/:user" element={<ClientApp/>}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="*" element={<ErrorPage/>}/>
            </Routes>
          </BrowserRouter>
        </FileContext.Provider>
      </OverlayContext.Provider>
    </div>
  );
}

export default App;
