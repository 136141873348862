import React, { useState } from 'react'
import { StyledInput, PasswordInput, ErrorAlert } from 'components'
import { updateUser } from 'services'
import { setToken, getToken } from 'utils'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

const { REACT_APP_USER_INFO_KEY } = process.env

const UserEdit = () => {
  const user = JSON.parse(getToken(REACT_APP_USER_INFO_KEY))
  const [ form, setForm ] = useState({
    name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: ''
  })
  const navigate = useNavigate()
  const [ toggle, setToggle ] = useState(false)
  const [ errors, setErrors ] = useState([])

  const handleChange = ({target}) => {
    const { value, name } = target
    setForm(prev => ({...prev, [name]: value}))
  }

  const getChangedFields = (currentValues, newValues) => {
    const keys = Object.keys(newValues)
    var values = {}
    keys.forEach(e => {
      values[e] = newValues[e] !== ''
        ? newValues[e]
        : currentValues[e]
    })
    return values
  }

  const handleSubmit = evt => {
    evt.preventDefault()
    const values = getChangedFields(user, form)

    updateUser(values).then(({user}) => {
      setToken(process.env.REACT_APP_USER_INFO_KEY, JSON.stringify(user))
      navigate(`/dashboard/${user.full_name_reversed}/system`)
    }).catch(({errors}) => {
      setErrors(errors)
      setToggle(true)
      setTimeout(() => {
        setErrors({})
        setToggle(false)
      }, 3000)
    })
  }

  return (
    <form onSubmit={handleSubmit} className="form-horizontal form-material">
      <div className={`${styles.error} ${toggle && styles.show}`}>
        <ErrorAlert toggle={toggle} handler={() => setToggle(false)}>
          <ul> 
            {
              Object.keys(errors).map((e, i) => {
                return(
                  <li key={i}>
                    <strong>{e}: </strong>{errors[e][0]}
                  </li>
                )   
              })
            }
          </ul>
        </ErrorAlert>
      </div>
      <StyledInput label="Nombres">
        <input type="text" name='name' value={form.name} 
          onChange={handleChange} 
          className="form-control form-control-line"
          placeholder={user.name} 
        />
      </StyledInput>
      <StyledInput label="Apellidos">
        <input type="text" name='last_name' value={form.last_name}
          onChange={handleChange} 
          className="form-control form-control-line"
          placeholder={user.last_name} 
        />
      </StyledInput>
      <StyledInput label="Email">
        <input type="email" name='email' value={form.email}
          onChange={handleChange} 
          className="form-control form-control-line" 
          placeholder={user.email} 
        />
      </StyledInput>
      <StyledInput label="Contraseña">
        <PasswordInput value={form.password}
          handler={handleChange} name='password'
          placeholder="********" className='m-0'
        />
      </StyledInput>
      <StyledInput label="Confirmar Contraseña">
        <PasswordInput value={form.password_confirmation}
          handler={handleChange} className='m-0'
          name='password_confirmation'
          placeholder="********"
        />
      </StyledInput>
      <StyledInput>
        <center className="p-20">
          <button type="submit" className="btn btn-success">
            Actualizar perfil
          </button>
        </center>
      </StyledInput>
    </form>
  )
}

export default UserEdit