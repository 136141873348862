import Axios from './config';

const UPDATE_USER = process.env.REACT_APP_UPDATE_USER;

const updateUser = async (form) => {
    try {
        const { data } = await Axios.put(UPDATE_USER, form);
        return data;
    } catch(error) {
        throw error.response.data
    }
}

export default updateUser;