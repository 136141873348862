import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './App.css'
import reportWebVitals from './reportWebVitals'

// importar los estilos aquí reduce el tamaño del bundle final
import './assets/plugins/bootstrap/css/bootstrap.min.css'
import './styles/css/style.css'
// import './styles/css/colors/blue.css'
import './styles/css/colors/default.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App />
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
