import React, { useState } from 'react'
import { HoverContext } from 'context'
import UserCard from '../UserCard'
import SidebarOptions from '../SidebarOptions'
import styles from './styles.module.scss'

const Sidebar = () => {
  const [ hover, setHover ] = useState(false)

  return (
    <div className={`${styles.sidebar} ${hover && styles['scroll-hover']}`}>
      <div className={styles.collapse}>
        <UserCard/>
      </div>
      <div className={styles.scroll}>
        <div className={styles.collapse}>
          <HoverContext.Provider value={{setHover}}>
            <SidebarOptions/>
          </HoverContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default Sidebar