import React, { UserImage, StyledButton } from 'components'
import styles from './styles.module.scss'

const UserCard = ({ children, ...props }) => {
  return (
    <li>
      <StyledButton styling={styles['user-btn']} {...props}>
        <UserImage user={children} styling={styles['user-img']}/>
        <span>{children}</span>
      </StyledButton>
    </li>
  )
}

export default UserCard