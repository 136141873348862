import hexToRgba from './hexToRgba'

export const TIME_FILTER = ['Mensual', 'Anual']

export const getChartData = (data, timeFilter) => {
  var dataset = {}
  const { translation } = data
  if (timeFilter === TIME_FILTER[0])
    dataset = data?.monthly
  else
    dataset = data?.yearly
  const dates = Object.keys(dataset)
  const labels = Object.keys(translation)

  return {
    labels: dates,
    datasets: labels.map(label => {
      return {
        label: translation[label].name,
        data: dates.map(date => dataset[date][label]),
        backgroundColor: hexToRgba(translation[label].color, 0.5),
        borderColor: translation[label].color,
        borderWidth: 1
      }
    })
  }
}