import Axios from "./config";

const JOIN_CHAT = process.env.REACT_APP_JOIN_CHAT;

const joinChat = async (chat_uuid) => {
  const response = await Axios.post(JOIN_CHAT, { chat_uuid });
  return response.data;
};

export default joinChat;
