import Axios from './config'
 
const { REACT_APP_CSRF_URL } = process.env

const validateCSRF = async() => {
  try {
    await Axios.get(REACT_APP_CSRF_URL)
  } catch (err) {
    console.error(err)
  }
}

export default validateCSRF