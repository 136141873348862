import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { ServerContext, SocketContext, UserContext } from "context";
import { NavDevider, NotificationBadge } from "components";
import { getSystems } from "services";
import NavButton from "../NavButton";
import NavTitle from "../NavTitle";
import styles from "./styles.module.scss";

const options = [
  {
    icon: "mdi mdi-file-chart",
    name: "Estadisticas",
    href: "analytics/systems",
  },
];

const SidebarOptions = () => {
  const { user } = useContext(UserContext);
  const { socket } = useContext(SocketContext)
  const { server } = useContext(ServerContext);
  const [systems, setSystems] = useState([]);
  const [systemsCount, setSystemsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSystems().then((data) => {
      setSystems(data.systems);
      setLoading(false);
      setSystemsCount(data.systems.length);
    });
  }, []);

  useEffect(() => {
    if (systemsCount && user) {
      socket?.private('notifications.user.' + user.id)
        .notification((data) => {
          setSystems(systems.map((system) => {
            if (system.name == data.client?.system_name) {
              system.unreadMessages = data.systemUnreadMessages;
            }
            return system;
          }))
        })
    }
  }, [socket, systemsCount]);

  const getListItem = (el) => {
    return (
      <NavButton
        active={el.name === server}
        key={el.name}
        name={el.name}
        href={el.href}
      >
        <i className={el.icon}></i>
        <span>{el.name}</span>
        {
          el.unreadMessages ? (
            <NotificationBadge info={el.unreadMessages} />
          ) : ""
        }
      </NavButton>
    );
  };

  return (
    <nav className={`sidebar-nav h-100 ${styles["sidebar-nav"]}`}>
      <ul id="sidebarnav">
        <NavTitle>SISTEMAS</NavTitle>
        {loading ? (
          <Skeleton count={5} />
        ) : systems.length > 0 ? (
          systems.map((e) => getListItem(e))
        ) : (
          <li className="text-center">No hay sistemas</li>
        )
        }
        <NavDevider />
        <NavTitle>HERRAMIENTAS</NavTitle>
        {options.map((e) => getListItem(e))}
        <li style={{ height: "2rem" }}></li>
      </ul>
    </nav>
  );
};

export default SidebarOptions;
