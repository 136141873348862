import React, { useState } from 'react'

const PasswordInput = ({value, handler, placeholder, className, ...props}) => {
  const [hide, setHide] = useState(true)
  const type = hide ? 'password': 'text'

  return (
    <div className={`form-group ${className}`}>
      <div className="input-group" id="show_hide_password">
        <input className="form-control" type={type} 
          placeholder={placeholder} value={value}
          autoComplete='off'
          onChange={handler} {...props} />
        <button className="btn btn-light btn-circle input-group-addon"
          onClick={()=>setHide(prev=>!prev)}
          type='button' tabIndex="-1"
        >
          <i className={`fa fa-eye${hide?'-slash':''}`}></i>
        </button>
      </div>
    </div>
  )
}

export default PasswordInput