import Axios from 'axios'
import { getToken } from 'utils'

Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
Axios.defaults.withCredentials = true

Axios.interceptors.request.use(
  config => {
    const token = getToken(process.env.REACT_APP_AUTH_TOKEN_KEY)
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default Axios