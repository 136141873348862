import CryptoJS from 'crypto-js'

const {
  REACT_APP_AES128_KEY,
  REACT_APP_CBC_KEY
} = process.env

const INIT_VECTOR = CryptoJS.enc.Utf8.parse(REACT_APP_CBC_KEY)
const KEY = CryptoJS.enc.Utf8.parse(REACT_APP_AES128_KEY)
const OPTIONS = {iv: INIT_VECTOR, mode: CryptoJS.mode.CBC}

export const encrypt = data => {
  let encrypted = CryptoJS.AES.encrypt(data, KEY, OPTIONS)
  return encrypted.toString()
}

export const decrypt = data => {
  let decrypted = CryptoJS.AES.decrypt(data, KEY, OPTIONS)
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const decryptAll = array => {
  return array.map(msg => {
    msg.msg = decrypt(msg.msg)
    return msg
  })
}