import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getSystems } from 'services'

const useValidSystem = () => {
  const [ server, setServer ] = useState(null)
  const { sys, user } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!sys) return
    getSystems().then(res =>  {
      let found = res.systems.find(el => el.name === sys)
      if (found)
        setServer(sys)
      else
        navigate(`/dashboard/${user}/system`)
    })
  }, [sys])

  return { server, setServer }
}

export default useValidSystem