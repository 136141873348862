import { useState, useContext, useEffect } from "react"
import { getSystemChats } from "services"
import { ServerContext } from "context"
import { decrypt } from 'utils'

const useSystemsChats = ({setClients}) => {
  const [chats, setChats] = useState(0)
  const { server } = useContext(ServerContext)

  useEffect(() => {
    if (server) {
      getSystemChats(server).then((data) => {
        setChats(data.chats.length)
        setClients(data.chats.map((chat) => {
          chat.client.lastMessage = chat.last_message_info
          chat.client.lastMessage.msg = decrypt(chat.client.lastMessage.msg)
          chat.client.unreadMessages = chat.unread_messages
          return chat.client
        }).sort((x, y) => {
          return new Date(x.lastMessage?.timestamp) < new Date(y.lastMessage?.timestamp) ? 1 : -1
        }));
      });
    }
  }, [server]);

  return chats
}

export default useSystemsChats