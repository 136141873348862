import React, { useState, useRef, lazy, Suspense }  from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setToken } from 'utils'
import { login } from 'services'
import { useAuthToken } from 'hooks'
import { PasswordInput, StyledInput } from 'components'
import { Spinner } from 'components'
const LazyBackground  = lazy(() => import('components/LazyBackground'))
const LazyLogo = lazy(() => import ('./LazyLogo'))

const { REACT_APP_AUTH_TOKEN_KEY, REACT_APP_USER_INFO_KEY } = process.env

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const ref = useRef(null)
  
  useAuthToken()

  const handleNavigation = (data) => {
    const { user } = data
    setToken(REACT_APP_AUTH_TOKEN_KEY, `${data.token_type} ${data.access_token}`)
    setToken(REACT_APP_USER_INFO_KEY, JSON.stringify(user))
    navigate(`/dashboard/${user.full_name_reversed}/system`)
  }

  const handleError = () => {
    setLoading(false)
    setEmail("")
    setPassword("")
    setError(true)
    ref.current.focus()
    setTimeout(() => setError(false), 3000)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true);
    login({email, password}).then((data) => {
      handleNavigation(data)
    }).catch(() => {
      handleError()
    });
  }

  return (
    <Suspense fallback={
      <div className='mt-auto mb-auto'>
        <Spinner />
      </div>
    }>
      <LazyBackground sidebar>
        <div className="login-box card" style={{overflow: 'hidden'}}>
          <div className="card-body">
            <form onSubmit={handleSubmit} 
              className="form-horizontal form-material" id="loginform"
            >
              <LazyLogo/>
              <StyledInput>
                <input required ref={ref} className="form-control" type="email" 
                  placeholder="Correo electrónico" value={email}
                  onChange={({target}) => setEmail(target.value)}/> 
              </StyledInput>
              <PasswordInput value={password} handler={({target}) => setPassword(target.value)}
                placeholder='Contraseña' required
              />
              <button type="submit" className={`${error ? 'btn-danger': 'btn-info'} btn-lg btn-block`}>
                { 
                  error ? "Error de credenciales":
                  loading ? "Cargando..." : "Iniciar sesión"
                }
              </button>       
            </form>
          </div>
        </div>
      </LazyBackground>
    </Suspense>
  )
}

export default Login