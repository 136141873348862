import React, { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ChatContext } from "context";
import styles from "./styles.module.scss";
import { useLastUrlParam, useChannel, useIsClient } from "hooks";
import ChatBody from './ChatBody'

const ChatArea = () => {
  const { chatStatus } = useContext(ChatContext)
  const { usr } = useParams()
  const { channel } = useChannel({usr})
  const { isClient } = useIsClient()
  useLastUrlParam({ param: usr, condition: channel === null})
  const [ bottom, setBottom ] = useState(false)
  const [ top, setTop ] = useState(true)
  const bottomRef = useRef(null)
  const [ hidden, setHidden ] = useState(true)

  const checkScrollBounderies = ({target}) => {
    const { scrollTop, scrollHeight, clientHeight } = target
    // revisa si el scroll esta hasta arriba 
    setTop(scrollTop === 0)
    // revisa si el scroll esta hasta abajo
    setBottom((scrollTop + clientHeight) === scrollHeight)
  }

  const handleHidden = length => {
    setHidden(!channel ? true: length)
  }

  return (
    <div className={`${styles.wrapper} ${hidden && styles.hidden}`} 
      onScroll={checkScrollBounderies}
    >
      <ul className={`chat-list ${styles.chat}`}>
        {
          channel === null ? 
          <li className={`${styles.wrapper} ${styles.center}`}>
            {!isClient && <h3>Seleccione un chat</h3>}
          </li> 
          : 
          <ChatBody hidden={handleHidden}
            bottom={bottomRef} 
            top={top} 
            setTop={setTop}
          />
        }
        {
          isClient &&
          <div className="d-flex justify-content-center">
            {(chatStatus == 'open' && isClient) && <small>En un momento será contactado por alguién de soporte</small>}
          </div>
        } 
      </ul>
      {
        channel !== null &&
        <i className={`mdi mdi-arrow-down ${styles.begin} ${!bottom && styles['show-begin']}`}
          onClick={() => bottomRef.current.scrollIntoView({ behavior: "smooth" })}
        />
      }
    </div>
  );
};

export default ChatArea;