import React, { useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CardContainer } from 'components'
import { ServerContext } from 'context'
import UserEdit from './UserEdit'
import styles from './styles.module.scss'

const Settings = () => {
  const { pathname } = useLocation()
  const url = pathname.replace(/[^/]*$/, 'profile')
  const { setServer } = useContext(ServerContext)

  useEffect(() => {
    setServer(undefined)
  }, []);

  return (
    <CardContainer>
      <div className={styles.container}>
        <div className="card-header p-20">
          <h2 className="p-10">Editar perfil</h2>
        </div>
        <div className="card-body">
          <UserEdit/>
        </div>
        <div className="card-footer p-20">
          <center>
            <Link to={url} className="btn btn-primary btn-rounded">
              Ver Perfil
            </Link>
          </center>
        </div>         
      </div>
    </CardContainer>
  )
}

export default Settings