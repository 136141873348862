import React from "react";
import { useUserConnection, useSocket } from "hooks";
import { UserContext, SocketContext } from "../../context";
import { getToken } from "../../utils";
import Widget from "../WidgetPage";

const ClientApp = () => {
  const user = useUserConnection();
  const socket = useSocket(getToken(process.env.REACT_APP_AUTH_TOKEN_KEY));
  
  return (
    <UserContext.Provider value={{ user }}>
      <SocketContext.Provider value={{ socket }}>
        <Widget />
      </SocketContext.Provider>
    </UserContext.Provider>
  );
};

export default ClientApp;
