import React from 'react'
import { UserSettings } from 'components'
import styles from './styles.module.scss'

const UserDropdown = ({ show }) => {
  return (
    <div className={`dropdown-menu animated flipInY ${styles.dropdown} ${show ? 'show': styles.disable}`}>
      <UserSettings/>
    </div>
  )
}

export default UserDropdown;