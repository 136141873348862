import React from 'react'
import { UserImage } from 'components'
import styles from './styles.module.scss'

const UserHeader = ({name, position}) => {
  return (
    <div className="card-header">
      <center className="m-t-30"> 
        <UserImage user={name} styling={styles.user}/>
        <h4 className="card-title m-t-10">{name}</h4>
        <h6 className="card-subtitle">{position}</h6>
      </center>
    </div>
  )
}

export default UserHeader