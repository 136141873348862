import React, { useState } from 'react'
import { ToggleContext } from 'context'
import { CardContainer } from 'components'
import ChatMainArea from './components/ChatMainArea'
import ChannelBar from './components/ChannelBar'
import ContribuitorBar from './components/ContribuitorBar'

const Widget = ({ showChannel }) => {
  const [toggle, setToggle] = useState(false)
  const [clientChat, setClientChat] = useState(null)
  const [client, setClient] = useState(null)

  return (
    <CardContainer>
      <ToggleContext.Provider value={{toggle, setToggle, clientChat, setClientChat, client, setClient}}>
        { showChannel && <ChannelBar/> }
        <ChatMainArea showChannel={showChannel}/>
        {clientChat ? <ContribuitorBar/> : ''}
      </ToggleContext.Provider>
    </CardContainer>
  )
}

export default Widget