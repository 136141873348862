import Axios from "./config";

const markAsSeen = async (chat) => {
  const { data } = await Axios.post(process.env.REACT_APP_MARK_AS_SEEN, {
    chat_uuid: chat,
  });
  return data;
};

export default markAsSeen;
